import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { URLS } from "../../../consts/urls";
import { appChainId } from "../../Top";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEtherscanButtonClick: () => void;
  onOpenseaButtonClick: () => void;
}

const ResultModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"sm"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          YOU’VE SUCCESSFULLY MINTED!
        </ModalHeader>
        <ModalBody paddingBottom={"24px"}>
          <Box
            textAlign={"center"}
            borderTop={"1px solid #000"}
            borderBottom="1px solid #000"
            fontWeight={"bold"}
            padding={"8px "}
            marginBottom={"16px"}
          >
            KUMALEON NFT GIVEAWAY
          </Box>
          <Text fontSize={14} mb="16px">
            We’re giving away a KUMALEON NFT to 10 winners picked from those who
            tweet "I just minted!" with the #CryptoRecord hashtag! Details on
            Discord!
          </Text>
          <StyledOutlinedButton
            style={{ marginBottom: "16px" }}
            onClick={() =>
              window.open(
                "https://twitter.com/intent/tweet?text=I%20just%20minted!%20%23CryptoRecord%20",
                "_blank",
                "noopener"
              )
            }
          >
            TWEET
            <img src="/img/arrow.svg" alt="" />
          </StyledOutlinedButton>
          <StyledButton
            onClick={() => {
              window.location.reload();
            }}
          >
            OK
          </StyledButton>
          <Box
            display={"flex"}
            gap={"16px"}
            pt={"8px"}
            justifyContent={"center"}
          >
            <StyledLink
              href={URLS[appChainId].opensea}
              target="_blank"
              rel="noopener noreferrer"
            >
              Etherscan
              <img src="/img/arrow.svg" alt="" />
            </StyledLink>
            <StyledLink
              href={URLS[appChainId].opensea}
              target="_blank"
              rel="noopener noreferrer"
            >
              Opensea
              <img src="/img/arrow.svg" alt="" />
            </StyledLink>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResultModal;

const StyledButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const StyledOutlinedButton = styled.button`
  width: 100%;
  padding: 10px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  img {
    transition: filter 0.3s ease-in-out;
  }
  &:hover {
    background-color: #000;
    color: #fff;

    img {
      filter: brightness(0) invert(1);
    }
  }
`;
const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
`;
