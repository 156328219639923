export const URLS: {
  [key: string]: {
    etherscan: string;
    etherscan_tx: string;
    opensea: string;
    discord: string;
  };
} = {
  "1": {
    etherscan:
      "https://etherscan.io/address/0xc048be32eaec60bd03c1f28df9836e958e4f5ef3",
    etherscan_tx: "https://etherscan.io/tx/",
    opensea: "https://opensea.io/collection/async-to-sync",
    discord: "https://discord.com/invite/kumaleon",
  },
  "5": {
    etherscan:
      "https://goerli.etherscan.io/address/0xedC668048dA383B33B214BDD1b02d29B2b7fA9ac",
    etherscan_tx: "https://goerli.etherscan.io/tx/",
    opensea: "https://opensea.io/collection/async-to-sync",
    discord: "https://discord.com/invite/kumaleon",
  },
};

export const ADDRESSES: { [key: string]: { address: string } } = {
  "1": {
    // mainnet
    address: "0xc048be32eaec60bd03c1f28df9836e958e4f5ef3",
  },
  "5": {
    // goerli
    address: "0xedC668048dA383B33B214BDD1b02d29B2b7fA9ac",
  },
};
