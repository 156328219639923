import { ComponentStyleConfig, extendTheme } from "@chakra-ui/react";

const breakpoints = ["680px", "1024px", "1440px"];
const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`,
};

const styles = {
  global: {
    body: {
      bg: "#F1F1F1",
      color: "#000",
    },
    a: {
      transition: "opacity 0.3s",
      _hover: {
        opacity: 0.6,
      },
    },
  },
};

export const theme = extendTheme({
  breakpoints,
  fonts,
  styles,
});
