import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClickOKButton: () => void;
}

const DisconnectModal: FC<Props> = ({ isOpen, onClose, onClickOKButton }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          Disconnect wallet?
        </ModalHeader>
        <ModalBody paddingBottom={"24px"}>
          <Box display={"flex"} flexDirection={["column", "row"]} gap={"8px"}>
            <StyledSecondButton
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </StyledSecondButton>
            <StyledButton onClick={onClickOKButton}>OK</StyledButton>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DisconnectModal;

const StyledSecondButton = styled.button`
  width: 100%;
  padding: 10px;
  border: 1px solid #000;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: 1px solid #000;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
  }
`;
