import {
  Box,
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEtherscanButtonClick: () => void;
}

const ProgressModal: FC<Props> = ({
  isOpen,
  onClose,
  onEtherscanButtonClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"sm"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          Please Wait ...
        </ModalHeader>
        <ModalBody paddingBottom={"24px"}>
          <Text textAlign={"center"} paddingBottom={"24px"}>
            Please wait at the same screen.
          </Text>
          <Box display={"flex"} justifyContent={"center"} mb="24px">
            <CircularProgress
              isIndeterminate
              color="#000"
              size={"100"}
              thickness="4px"
            />
          </Box>
          <StyledButton onClick={onEtherscanButtonClick}>
            Etherscan
            <img src="/img/arrow.svg" alt="" />
          </StyledButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProgressModal;

const StyledButton = styled.button`
  width: 100%;
  padding: 10px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  img {
    transition: filter 0.3s ease-in-out;
  }
  &:hover {
    background-color: #000;
    color: #fff;

    img {
      filter: brightness(0) invert(1);
    }
  }
`;
