import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          FAILED TO MINT
        </ModalHeader>
        <ModalBody paddingBottom={"24px"}>
          <Text textAlign={"center"} paddingBottom={"24px"}>
            (Possibly wallet balance is insufficient) Please reload an app and
            try again
          </Text>
          <StyledButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </StyledButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;

const StyledButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
