import { Box, Grid, GridItem, Text, useMediaQuery } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, memo, useEffect, useRef, useState } from "react";
import ConnectModal from "../Modal/ConnectModal";
import ErrorModal from "../Modal/ErrorModal";
import ProgressModal from "../Modal/ProgressModal";
import ResultModal from "../Modal/ResultModal";
import { ADDRESSES, URLS } from "../../consts/urls";
import ComingSoonModal from "../Modal/ComingSoonModal";
import SelectModal from "../Modal/SelectModal";
import { abi } from "../../abi";
import {
  Address,
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useSwitchNetwork,
  useWaitForTransaction,
  useDisconnect,
} from "wagmi";
import { ethers } from "ethers";
import DisconnectModal from "../Modal/DisconnectModal";

export const appChainId: string = process.env.REACT_APP_CHAIN_ID ?? "1";
const contractAddress = ADDRESSES[appChainId].address;

const Top: FC = () => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [rightPartsHeight, setRightPartsHeight] = useState(0);

  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [amount, setAmount] = useState(1);

  const { data: tokenRemaining } = useContractRead({
    address: contractAddress as Address,
    abi,
    functionName: "tokenRemaining",
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID ?? "1"),
  });
  const { data: isMintStarted } = useContractRead({
    address: contractAddress as Address,
    abi,
    functionName: "onSale",
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID ?? "1"),
  });

  const { config, error } = usePrepareContractWrite({
    address: contractAddress as Address,
    abi,
    functionName: "batchMint",
    args: [account, amount],
    overrides: {
      value: ethers.utils.parseEther("0.1").mul(amount),
    },
  });
  const { data, write: mintBatch } = useContractWrite(config);

  const { isLoading, isError, isIdle, isSuccess, status } =
    useWaitForTransaction({ hash: data?.hash });

  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);

  const [comingsoonModalOpen, setComingsoonModalOpen] = useState(false);

  const { disconnect } = useDisconnect();

  console.log(isIdle, isLoading, isSuccess, isError, status, data);

  useEffect(() => {
    setRightPartsHeight(titleRef.current?.offsetHeight ?? 0);
  }, []);

  useEffect(() => {
    if (!!account) {
      setConnectModalOpen(false);
    }
  }, [account]);

  useEffect(() => {
    if (status === "idle") {
      setSelectModalOpen(false);
      setProgressModalOpen(false);
    } else if (status === "loading") {
      setSelectModalOpen(false);
      setProgressModalOpen(true);
    } else if (status === "error") {
      setSelectModalOpen(false);
      setProgressModalOpen(false);
      setErrorModalOpen(true);
    } else if (status === "success") {
      setSelectModalOpen(false);
      setProgressModalOpen(false);
      setResultModalOpen(true);
    }
  }, [status]);

  const isMobile = useMediaQuery("(max-width: 1023px)")[0];

  const handleMintButtonClick = () => {
    const f = async () => {
      if (
        chain !== undefined &&
        chain.id != parseInt(process.env.REACT_APP_CHAIN_ID ?? "1")
      ) {
        if (switchNetwork !== undefined) {
          switchNetwork(parseInt(process.env.REACT_APP_CHAIN_ID ?? "1"));
        }
      } else {
        if (isMintStarted) {
          setSelectModalOpen(!!account);
          setConnectModalOpen(!account);
        } else {
          setComingsoonModalOpen(!isMintStarted);
        }
      }
    };
    f();
  };

  const handleWalletConnectButtonClick = () => {
    if (!!account) {
      setDisconnectModalOpen(true);
    } else {
      setConnectModalOpen(true);
    }
  };

  const handleEtherscanButtonClickInProgressModal = () => {
    if (data?.hash === undefined) {
      return;
    }
    window.open(
      `${URLS[appChainId].etherscan_tx}${data?.hash}`,
      "_blank",
      "noopener noreferrer"
    );
  };

  const handleEtherscanButtonClickInResultModal = () => {
    // jump to etherscan link (contract address)
    window.open(URLS[appChainId].etherscan, "_blank", "noopener noreferrer");
  };
  const handleOpenseaButtonClickInResultModal = () => {
    // jump to opensea link (collection)
    window.open(URLS[appChainId].opensea, "_blank", "noopener noreferrer");
  };

  return (
    <>
      <main>
        <Box>
          <Grid templateColumns={["1fr", "repeat(2, 1fr)"]}>
            <GridItem w="100%" overflow={"auto"} height={["auto", "100vh"]}>
              <Grid templateRows={"repeat(7, auto)"}>
                <Box
                  height={["auto", "8.9vh"]}
                  ref={titleRef}
                  position={"relative"}
                  padding={"16px"}
                  borderBottom={"1px solid #000"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  minHeight={["auto", "70px"]}
                >
                  <Text
                    fontSize={["20px", "2.2vw", "32px"]}
                    fontWeight={700}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                  >
                    Async to Sync
                  </Text>
                </Box>
                <Box
                  padding={"20px"}
                  height={["auto", "52.7vh"]}
                  display={["flex", "block"]}
                  alignItems={"center"}
                >
                  <Box
                    width={["100%", "auto"]}
                    maxWidth={["500px", "auto"]}
                    height={["auto", "100%"]}
                    margin={"0 auto"}
                    border={"1px dashed #848484"}
                    style={{
                      aspectRatio: "1 / 1",
                    }}
                  >
                    <video
                      src="/mov/web-demo.mp4"
                      controls
                      controlsList="nodownload"
                      contextMenu="return false;"
                      poster="/img/thumbnail.jpg"
                    ></video>
                  </Box>
                </Box>
                <Box
                  width={"100%"}
                  height={["auto", "3.9vh"]}
                  overflow={"hidden"}
                  borderTop={"1px solid #000"}
                  borderBottom={"1px solid #000"}
                  minHeight={["auto", "40px"]}
                >
                  <Text
                    fontSize={"12px"}
                    letterSpacing={"0.225em"}
                    padding={"12px"}
                    textAlign={"center"}
                  >
                    Network : Ethereum | Standard : ERC721 | Max Supply : 256
                  </Text>
                </Box>
                <Box
                  height={["auto", "6.9vh"]}
                  borderBottom={"1px solid #000"}
                  padding={"16px"}
                  minHeight={["auto", "54px"]}
                >
                  <Box
                    maxW={"500px"}
                    margin={"0 auto"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Text
                      fontSize={["20px", "2.2vw", "32px"]}
                      lineHeight={"1"}
                      fontWeight={700}
                      textAlign={"center"}
                      as="span"
                    >
                      <span>Ξ 0.1</span>
                    </Text>
                  </Box>
                </Box>
                <Box
                  height={["auto", "3.9vh"]}
                  borderBottom="1px solid #000"
                  padding={"8px"}
                  textAlign="center"
                  minHeight={["auto", "30px"]}
                >
                  <Text fontSize={["12px", "16px"]}>
                    <span style={{ fontWeight: "bold" }}>
                      KUMALEON Holder: BUY 1 GET 1 FREE.
                    </span>{" "}
                    Details on{" "}
                    <a
                      href={URLS[appChainId].discord}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      the KUMALEON Discord.
                    </a>
                  </Text>
                </Box>
                {!isMobile && (
                  <>
                    <Box
                      height={["auto", "13.5vh"]}
                      minHeight={["auto", "120px"]}
                    >
                      <MintButton
                        onClick={handleMintButtonClick}
                        disabled={tokenRemaining === 0}
                      >
                        <Text
                          fontSize={"2.2vw"}
                          textAlign={"center"}
                          letterSpacing={"0.225em"}
                          as={"span"}
                        >
                          {tokenRemaining !== 0 ? "MINT" : "SOLD OUT"}
                        </Text>
                      </MintButton>
                    </Box>
                    <Box
                      height={["auto", "7.1vh"]}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      padding={"16px"}
                      minHeight={"50px"}
                    >
                      <button
                        onClick={handleWalletConnectButtonClick}
                        disabled={!isMintStarted}
                      >
                        <Text
                          fontSize={"12px"}
                          textAlign={"center"}
                          letterSpacing={"0.225em"}
                          textDecoration={
                            isMintStarted ? "underline" : "line-through"
                          }
                        >
                          {!!account ? account : "Connect Wallet"}
                        </Text>
                      </button>
                    </Box>
                  </>
                )}
              </Grid>
            </GridItem>
            <GridItem
              borderLeft="1px solid #000"
              w="100%"
              height={["auto", "100vh"]}
              overflow={"auto"}
            >
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderBottom={isMobile ? undefined : "1px solid #000"}
                  height={`${rightPartsHeight}px`}
                >
                  <img
                    style={{ maxWidth: "44px" }}
                    src="/img/logo.png"
                    alt="KUMALEON"
                  />
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    Artists
                  </Text>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    gap={"16px"}
                    padding={"32px"}
                    borderBottom={"1px solid #000"}
                  >
                    <Box minHeight={"118px"} style={{ aspectRatio: "1/1" }}>
                      <img src="/img/ryota.jpg" alt="" />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                      <Box>
                        <Text fontSize={"14px"} textTransform={"uppercase"}>
                          Electronic musician / Track maker
                        </Text>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text fontSize={"16px"} fontWeight={700}>
                            ryota
                          </Text>
                          <a
                            href="https://twitter.com/8bit303"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            <img src="/img/twitter.svg" alt="Twitter" />
                          </a>
                        </Box>
                      </Box>
                      <Text fontSize={"14px"} maxW={"600px"}>
                        Electronic musician / Track maker. With an academic
                        background in music, he learned the foundations of
                        digital recording in his teens. He later found his
                        passion for bass and wood bass and started working on
                        DTM music production. Creating breakcore music and
                        organizing music events for various clubs in Tokyo, then
                        became interested in NFT and working as a track creator
                        for 8bit Acid Lab, a music group.
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={"16px"}
                    padding={"32px"}
                    borderBottom={"1px solid #000"}
                  >
                    <Box minHeight={"118px"} style={{ aspectRatio: "1/1" }}>
                      <img src="/img/hasaqui.jpg" alt="" />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                      <Box>
                        <Text fontSize={"14px"} textTransform={"uppercase"}>
                          Generative Artist
                        </Text>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text fontSize={"16px"} fontWeight={700}>
                            hasaqui
                          </Text>
                          <a
                            href="https://twitter.com/hasaqui"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            <img src="/img/twitter.svg" alt="Twitter" />
                          </a>
                        </Box>
                      </Box>
                      <Text fontSize={"14px"} maxW={"600px"}>
                        Artist / Researcher. Based in Tokyo. Drawing art and
                        generating images using AI and started working actively
                        in Generative Art since 2022. One of the collab artists
                        of KUMALEON. He’s been researching on NFT art through
                        critique and contributing to a number of books. He is
                        participating in Proof of X, an exhibition on the medium
                        of Blockchain this year.
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={"16px"}
                    padding={"32px"}
                    borderBottom={"1px solid #000"}
                  >
                    <Box minHeight={"118px"} style={{ aspectRatio: "1/1" }}>
                      <img src="/img/ara.png" alt="" />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                      <Box>
                        <Text fontSize={"14px"} textTransform={"uppercase"}>
                          Smart Contract Artist
                        </Text>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text fontSize={"16px"} fontWeight={700}>
                            Ara
                          </Text>
                          <a
                            href="https://twitter.com/arandoros"
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            <img src="/img/twitter.svg" alt="Twitter" />
                          </a>
                        </Box>
                      </Box>
                      <Text fontSize={"14px"} maxW={"600px"}>
                        Engineer / Artist. Developing on various chains since
                        2017 and exploring the structural and poetic aspects of
                        code using blockchain and smart contracts since 2022.
                        Participated in CRYPTO ART FES 2022, Crypto Art Week
                        Asia Tokyo 2022. This year he is joining the Proof of X
                        exhibition in 2023.
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderTop={"2px solid #000"}
                    borderBottom={"2px solid #000"}
                    padding={"6px 0"}
                  >
                    <Box
                      borderTop={"1px solid #000"}
                      borderBottom={"1px solid #000"}
                      display={"flex"}
                      flexDirection={isMobile ? "column" : "row"}
                    >
                      <LinkButton
                        style={{
                          borderRight: "1px solid #000",
                          borderBottom: isMobile ? "1px solid #000" : undefined,
                        }}
                        onClick={() =>
                          window.open(
                            URLS[appChainId].etherscan,
                            "_blank",
                            "noopener noreferrer"
                          )
                        }
                      >
                        Etherscan
                        <img src="/img/arrow.svg" alt="" />
                      </LinkButton>
                      <LinkButton
                        onClick={() =>
                          window.open(
                            URLS[appChainId].discord,
                            "_blank",
                            "noopener noreferrer"
                          )
                        }
                      >
                        Discord
                        <img src="/img/arrow.svg" alt="" />
                      </LinkButton>
                    </Box>
                  </Box>
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    What’s this?
                  </Text>
                </Box>
                <Box padding={"32px 32px 80px"} borderBottom={"1px solid #000"}>
                  <Text maxW={"600px"}>
                    Async to Sync is an art series inspired by the relationship
                    between generative art and generative music. The music and
                    visuals are asynchronously and synchronously triggered by
                    the smart contract in a generative way to explore new
                    possibilities while taking advantage of their different
                    unique characteristics. The NFT is composed of four layers,
                    each of which has four sound elements, each lasting 3
                    minutes 20 seconds and they all come together to be a single
                    piece of art.
                  </Text>
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    Music
                  </Text>
                </Box>
                <Box padding={"32px 32px 80px"} borderBottom={"1px solid #000"}>
                  <Text maxW={"600px"}>
                    The music created for this project has a structure as a
                    piece of music is over 3 minutes long, and 256 patterns have
                    been generated to express the contingency by using the Smart
                    Contract mechanism. If the music is mainly acoustic or drone
                    music without a structure, it is relatively easy to create
                    hundreds of patterns of music, even when combining random or
                    accidental materials, because the sound elements can be
                    easily blended with each other. This time however, we
                    focused on creating a long lasting generative music with a
                    defined structure and time dimension.
                  </Text>
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    Art
                  </Text>
                </Box>
                <Box padding={"32px 32px 32px"} borderBottom={"1px solid #000"}>
                  <Text paddingBottom={"40px"} maxW={"600px"}>
                    The generative art synchronizes with the dynamics of the
                    music volume and waves. The base on the hash at the time of
                    mint, the color and the parts of the image which includes
                    the rarity are set , but every time the music is played, the
                    main object and other parts randomly changes with four
                    different patterns. By adding the randomness defined by the
                    code to the musical waves, the "generative" quality unfolds
                    as different versions of the image are captured each time
                    the music is played. In terms of modeling, geometric objects
                    are drawn rotating in 3D. For higher rarity pieces, more
                    scanning lines and unique color effects are applied to the
                    object allowing each minted piece to enjoy its own unique
                    artistic look.
                  </Text>
                  <img src="/img/art.jpg" alt="" />
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    Contract
                  </Text>
                </Box>
                <Box padding={"32px 32px 80px"} borderBottom={"1px solid #000"}>
                  <Text maxW={"600px"}>
                    The question that I've been trying to answer is "What does
                    it really mean to create art on the blockchain?". And smart
                    contract art provides the answer. Like the randomness in
                    music and generative art, smart contracts also generate
                    contingencies born out of the blockchain. When retrieving
                    the metadata, 12 messages are randomly selected from the
                    thousands of messages logged in the most oldest smart
                    contract “Terra nullius” and given to the generative art
                    code. This is the people's voices and the chain's unique
                    memories. The onchain cutup changing with each metadata
                    refresh forms the fundamental meaning of the existence of
                    the artwork in the visual expression.
                  </Text>
                </Box>
                <Box padding={"24px"} borderBottom={"1px solid #000"}>
                  <Text
                    fontSize={"24px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    About CRYPTO RECORD
                  </Text>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  padding={"32px"}
                  borderBottom={"1px solid #000"}
                  gap={"24px"}
                >
                  <Box width={["100%", "30%"]} minWidth={"260px"}>
                    <img src="/img/record.png" alt="Crypto Record" />
                  </Box>
                  <Text maxW={"600px"}>
                    CryptoRecord is an exploration of the possibilities of
                    generative art and music produced by Kumaleon, and Async to
                    Sync is the first collection to address these relationships
                    and the future.
                  </Text>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"16px"}
                    paddingTop={"16px"}
                  >
                    <a
                      href={URLS[appChainId].opensea}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ maxWidth: "24px" }}
                    >
                      <img src="/img/opensea.png" alt="OpenSea" />
                    </a>
                    <a
                      href={URLS[appChainId].etherscan}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ maxWidth: "24px" }}
                    >
                      <img src="/img/etherscan.png" alt="Etherscan" />
                    </a>
                    <a
                      href={URLS[appChainId].discord}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ maxWidth: "24px" }}
                    >
                      <img src="/img/discord.png" alt="Discord" />
                    </a>
                  </Box>
                </Box>
                <Box>
                  <Text
                    textAlign={"center"}
                    padding={"16px"}
                    paddingBottom={isMobile ? "118px" : undefined}
                    fontSize={"14px"}
                    textTransform={"uppercase"}
                  >
                    Crypto Record from KUMALEON
                  </Text>
                </Box>
              </Box>
            </GridItem>
          </Grid>
          {isMobile && (
            <Box position={"fixed"} bottom={0} left={0} width={"100%"}>
              <Box>
                <MintButtonSP
                  onClick={handleMintButtonClick}
                  disabled={tokenRemaining === 0}
                >
                  <Text
                    fontSize={"16px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    as={"span"}
                  >
                    {tokenRemaining ? "MINT" : "SOLD OUT"}
                  </Text>
                </MintButtonSP>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={"12px 16px"}
                background={"#F1F1F1"}
              >
                <button
                  onClick={handleWalletConnectButtonClick}
                  disabled={!isMintStarted}
                >
                  <Text
                    fontSize={"12px"}
                    textAlign={"center"}
                    letterSpacing={"0.225em"}
                    textDecoration={
                      isMintStarted ? "underline" : "line-through"
                    }
                  >
                    {!!account ? account : "Connect Wallet"}
                  </Text>
                </button>
              </Box>
            </Box>
          )}
        </Box>
      </main>
      <ConnectModal
        isOpen={connectModalOpen}
        onClose={() => {
          setConnectModalOpen(false);
        }}
      />
      <ErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
      />
      <ProgressModal
        isOpen={progressModalOpen}
        onClose={() => setProgressModalOpen(false)}
        onEtherscanButtonClick={handleEtherscanButtonClickInProgressModal}
      />
      <ResultModal
        isOpen={resultModalOpen}
        onClose={() => setResultModalOpen(false)}
        onEtherscanButtonClick={handleEtherscanButtonClickInResultModal}
        onOpenseaButtonClick={handleOpenseaButtonClickInResultModal}
      />
      <ComingSoonModal
        isOpen={comingsoonModalOpen}
        onClose={() => setComingsoonModalOpen(false)}
      />
      <SelectModal
        isOpen={selectModalOpen}
        onClose={() => setSelectModalOpen(false)}
        mintBatch={mintBatch}
        amount={amount}
        setAmount={setAmount}
      />
      <DisconnectModal
        isOpen={disconnectModalOpen}
        onClose={() => setDisconnectModalOpen(false)}
        onClickOKButton={() => {
          setDisconnectModalOpen(false);
          disconnect();
        }}
      />
    </>
  );
};

export default memo(Top);

const MintButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 40px 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-bottom: 1px solid #000;
  &:hover {
    background-color: #f1f1f1;
    color: #000;
  }
`;

const MintButtonSP = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 16px;
`;

const LinkButton = styled.button`
  width: 100%;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  img {
    transition: filter 0.3s ease-in-out;
  }
  &:hover {
    background-color: #000;
    color: #fff;

    img {
      filter: brightness(0) invert(1);
    }
  }
`;
