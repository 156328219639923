import React from "react";
import "./App.css";
import Top from "./component/Top";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./style/theme";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Top />
    </ChakraProvider>
  );
}

export default App;
