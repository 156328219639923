import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { useAccount } from "wagmi";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mintBatch: any;
  amount: number;
  setAmount: (amount: number) => void;
}

const SelectModal: FC<Props> = ({
  isOpen,
  onClose,
  mintBatch,
  amount,
  setAmount,
}) => {
  const { address: account } = useAccount();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          Choose the number to mint
        </ModalHeader>
        <ModalBody paddingBottom={"24px"}>
          <Box mb={"16px"}>
            <Select
              variant="outline"
              value={amount}
              onChange={(e) => {
                setAmount(parseInt(e.target?.value));
              }}
            >
              {[...Array(10)].map((_, i) => (
                <option value={i + 1}>{i + 1}</option>
              ))}
            </Select>
          </Box>
          <StyledButton
            disabled={account === undefined}
            onClick={() => {
              mintBatch();
              onClose();
            }}
          >
            OK
          </StyledButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectModal;

const StyledButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
