import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { useConnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectLegacyConnector } from "wagmi/connectors/walletConnectLegacy";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ConnectModal: FC<Props> = ({ isOpen, onClose }) => {
  const { connect: connectInjected } = useConnect({
    connector: new InjectedConnector(),
  });
  const { connect: connectWalletConnect } = useConnect({
    connector: new WalletConnectLegacyConnector({ options: { qrcode: true } }),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay />
      <ModalContent border={"1px solid #000"} borderRadius={"0"}>
        <ModalHeader paddingTop={"32px"} textAlign={"center"}>
          Connect Wallet with
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display={"flex"} paddingBottom={"24px"}>
          <StyledButton onClick={() => connectInjected()}>
            MetaMask
          </StyledButton>
          <StyledButton onClick={() => connectWalletConnect()}>
            WalletConnect
          </StyledButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectModal;

const StyledButton = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
